import React from "react"
import { Button, Tabs, Tab, Form, Container, Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import tablogo from '../../images/tab-logo.png';
import Starberry from "@images/starberry.svg";
import { useStaticQuery, graphql } from "gatsby";
import GetURL from '@Components/common/site/get-url';
import './Footer.scss';
const Footer = (props) => {
    const year = new Date().getFullYear();
    const data = useStaticQuery(graphql`
    query{
        glstrapi {
            menuFooter(publicationState: LIVE) {
                Publish
                Parent_Label {
                  Label
                  Footer_Menu {
                    Label
                    URL {
                      Alias
                    }
                  }
                }
              }
              contact(publicationState: LIVE) {
                Address
                Email
                Facebook_Link
                Instagram_Link
                Linkedin_Link
                Phone
                Twitter_Link
              }
    }
}
    `);

    return (
        <footer className="footer-page">
            <Container>
                <Row>
                    <Col>
                        <div className="footer-wrapper">
                        {data.glstrapi?.menuFooter?.Parent_Label?.map((item, i) => (
                            <div className={`footer-content ${i === 0 ? "order-md-2" : i === 1 ? "order-md-1 order-lg-3"  : i === 2 ? "order-md-3 order-lg-4" : i === 3 ? "order-md-5" : ""}`}>
                                <h5>{item?.Label}</h5>
                                <ul className="footer-menu">
                                {item?.Footer_Menu?.map((newitem, j) => (
                                    <li>
                                        <GetURL label={newitem.Label} alias={newitem.URL?.Alias} />
                                    </li>))}
                                </ul>
                            </div>
                            ))}
                            <div className="social-links order-md-5 order-lg-1">
                                <Link to={'/'} className="d-none d-lg-block order-lg-1 logo ">
                                    <img src={tablogo} alt="logo" />
                                </Link>
                                <ul className="social-icon">
                                    {data.glstrapi?.contact?.Facebook_Link &&
                                    <li>
                                        <Link href={data.glstrapi?.contact?.Facebook_Link} target="_blank">
                                            <i className="icon-fb"></i>
                                        </Link>
                                    </li>
                                    }
                                    {data.glstrapi?.contact?.Linkedin_Link &&
                                    <li>
                                        <Link href={data.glstrapi?.contact?.Linkedin_Link}  target="_blank">
                                            <i className="icon-linkedln"></i>
                                        </Link>
                                    </li>
                                    }
                                    {data.glstrapi?.contact?.Instagram_Link &&           
                                    <li>
                                        <Link href={data.glstrapi?.contact?.Instagram_Link}  target="_blank">
                                            <i className="icon-insta"></i>

                                        </Link>
                                    </li>
                                    }
                                    {data.glstrapi?.contact?.Twitter_Link &&
                                    <li>
                                        <Link href={data.glstrapi?.contact?.Twitter_Link}  target="_blank">
                                            <i className="icon-twitter"></i>
                                        </Link>
                                    </li>
                                    }       
                                </ul>
                                <div className="sm-info">
                                    <Link to="/terms-and-conditions" className="cookies" >
                                        Terms & Conditions</Link> / <Link to="/privacy-and-cookies-policy" className="cookies" >Privacy & Cookies
                                 </Link>
                                    <Link className="reserve">
                                        © Allsopp & Allsopp Recruitment {year}. All Rights Reserved.
                                 </Link>
                                    <Link href="#" className="site-link">
                                        Site by <a href={'http://starberry.tv/'} target="_blank"><img src={Starberry} alt="Starberry" className="stb-logo" /></a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>)
};
export default Footer;