/**
 * Wishlist link
 */

import React, { useEffect } from "react"
import { getCookie } from "./utils/cookie"
import { Link } from "@StarberryUtils"
import $ from "jquery"

export const WishlistCount = () => {
  useEffect(() => {
    let wishlistItems = getCookie("favourites")
    $(".wishlist-count").text(wishlistItems.length)
    if (wishlistItems.length) $("html").addClass("has-wishlist")
  }, [])

  return (
    <Link to="/job-wishlist" className="white-header-bk">
      <div className="wishlist-top-icon">
        <i className="icon icon-heart"></i>
        <div className="wishlist-count d-flex align-items-center">0</div>
      </div>
    </Link>
  )
}
