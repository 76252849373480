import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "@StarberryUtils"
import $ from "jquery";
import { navigate } from "@reach/router"
import Select from 'react-select';
import SearchInputBoxJob from './search-inputbox-job'
import SearchInputBoxIndustry from './search-inputbox-industry'
import SearchInputBoxLocation from './search-inputbox-location'

const CommonSearchHeader = () => {
    
    const [filter, setFilter] = useState('job')
    const filterChange = (e) => {
        setFilter(e.value)
    }
    
    let url = "/for-candidates/job-search/";

    const searchResults = (e) => {
        if( filter === 'job' ) {
            var jobFilter = $(".searchby-job .react-autosuggest__input").val();
            var searcValue = ""

            if(jobFilter === "Salesforce Specialist - Admin and Implementation") {
                searcValue = "salesforce-specialist-admin"
            } else {
                searcValue = $(".searchby-job .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").replace("/","-").replace(".","").replace("(","").replace(")","").replace(/&/g,"-").replace(/---/g,"-").toLowerCase();
            }

            // var searcValue = $(".searchby-job .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").replace("/","-").replace(".","").replace("(","").replace(")","").replace(/Admin/g,'').replace(/&/g,"-").replace(/---/g,"-").toLowerCase();
            // var searcValue = $(".searchby-job .react-autosuggest__input").val().replace(/ /g,'-').replace("Admin",'-').replace(/---/g,'-').toLowerCase();

            if(searcValue !== "") {
                url = "job-"+searcValue+"/";
                navigate("/for-candidates/job-search/"+url);
            } else {
                navigate("/for-candidates/job-search/");
            }
        } else if ( filter === 'industry' ) {
            var searcValue = $(".searchby-industry .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();

            if(searcValue !== "") {
                url = "industry-"+searcValue+"/";
                navigate("/for-candidates/job-search/"+url);
            } else {
                navigate("/for-candidates/job-search/");
            }
        } else if ( filter === 'location' ) {
            var searcValue = $(".searchby-location .react-autosuggest__input").val().split(', ').join('-').replace(/ /g,"-").replace(/'/g,"").toLowerCase();
            
            if(searcValue !== "") {
                url = "city-"+searcValue+"/";
                navigate("/for-candidates/job-search/"+url)
            } else {
                navigate("/for-candidates/job-search/");
            }
        }
    }

    // Dropdown react select styles
    const customStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#0c203c" : null,
                color: isFocused ? "#ffffff" : "#000000"
            };
        },
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0, 
            paddingLeft: 0,
            outline: 0, 
            boxShadow: 'none', 
            color: '#0c203c',
            fontSize: '1.125rem',
            fontWeight:'bold'
        }),
        valueContainer: (styles) => ({
            ...styles,
            fontSize: '1.125rem',
            paddingLeft: 0,
            lineHeight: '30px',
            fontWeight:'bold',
            cursor: 'pointer'
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#ffffff",
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#ffffff',
            cursor: 'pointer'
        }),
        indicatorSeparator: () => null,
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#0c203c',
                marginLeft: 0
            }
        },
    }

    const DownIndicator = () => (
        <>
            <i className={ "icon-filter-dropdown" }></i>
        </>
    )
    // Dropdown react select styles

    return (
        <>
            <Form className="tab-form common-header-search">
                <div className="select-box-custom">
                    <Select
                        options={[
                            { value: "job", label: "Find by Job" },
                            { value: "industry", label: "Find by Industry" },
                            { value: "location", label: "Find by Location" },
                        ]}
                        placeholder="Find by Job"
                        classNamePrefix={"select-box"}
                        onChange={filterChange}
                        styles={customStyles}
                        isSearchable={false}
                        components={{ DropdownIndicator: () => <DownIndicator /> }}
                    />
                </div>
                <div className="input-field property-search_overlay">
                    {
                        filter === 'job' ?
                            <div className="searchby-job">
                                <SearchInputBoxJob />
                            </div>
                        :
                        filter === 'industry' ?
                            <div className="searchby-industry">
                                <SearchInputBoxIndustry />
                            </div>
                        :
                        filter === 'location' ?
                            <div className="searchby-location">
                                <SearchInputBoxLocation />
                            </div>
                        : null 
                    }
                </div>
                <a href="javascript:void(0)" className="search" onClick={searchResults}>
                    <i className="icon-search"></i>
                </a>
            </Form>
        </>
    )
}

export default CommonSearchHeader